import * as React from "react"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import DynamicComponent from "../components/DynamicComponent"
import useStoryblok from "../utils/storyblok"

const Page = ({ pageContext, location }) => {
  let story = pageContext.story
  story = useStoryblok(story, location)

  const pageColor = story.content?.background_color
    ? story.content?.background_color?.color
    : "transparent"

  const textColor = story.content?.text_color
    ? story.content?.text_color?.color
    : "inherit"

  const components = story.content.body
    .filter((childBlok) => childBlok.visibility !== true)
    .map((blok) => {
      return <DynamicComponent blok={blok} key={blok._uid} />
    })

  return (
    <Layout
      bgColor={pageColor}
      textColor={textColor}
      paddedContent={true}
      location={location}
    >
      <Seo title={story.content.title} />
      {components}
    </Layout>
  )
}

export default Page
